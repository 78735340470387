<template>
  <!-- eslint-disable vue/html-closing-bracket-newline -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <b-modal id="modal-xl" ok-title="Save" centered @ok="handlerSubmit">
    <Overplay v-if="isShow" />
    <div class="container-ctrl">
      <b class="h1">{{ $t('Add more currency') }}</b>
      <p>{{ $t('Select the currency to make your website available in') }}.</p>
      <hr />
      <div class="container-ctrl">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-for="(item, index) in data"
            :key="index"
            v-model="selectedCurrency"
            :value="item"
            name="lang-radio"
            :disabled="item.disable"
          >
            <span>
              <img
                style="width: 25px; height: 25px; object-fit: contain;"
                class="mx-1"
                :src="item.img"
                :alt="item.iso_code"
              />
              {{ item.iso_code }}  ({{ item.name }})
            </span>
          </b-form-radio>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BFormRadio } from 'bootstrap-vue'
import general from '@/mixins/index'

export default {
  components: {
    BFormRadio,
  },
  mixins: [general],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isShow: false,
      selectedList: [],
      selectedCurrency: {},
    }
  },
  // mounted() {
  //   this.loadCategoryList()
  // },
  methods: {
    handlerSubmit() {
      this.selectedCurrency.is_active = true
      this.$emit('handlerChoosecurrency', this.selectedCurrency)
    },
    // async loadCategoryList(id, level) {
    //   const siteId = JSON.parse(localStorage.getItem('siteID')).id
    //   try {
    //     this.isShow = true
    //     const result = await Request.get(
    //       this.$http,
    //       `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
    //     )
    //     if (result.status === 200) {
    //       this.isShow = false
    //       const res = result.data.data
    //       this.tree = await res
    //       if (id !== undefined) {
    //         this.loadSelectedCategory(id, level)
    //       }
    //     }
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Opps! Something wrong',
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger',
    //         text: String(error),
    //       },
    //     })
    //   }
    // },
  },
}
</script>
<style lang="sass" scoped>
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem

        .active
          font-weight: 500
          background-color: #f5f5f5
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
