<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <div>
    <Overplay v-if="isShow" />
    <PopupCurrency
      :data="currencyList"
      @handlerChoosecurrency="handlerChoosecurrency"
    />
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="9">
            <Fieldset id="currencysetting" :title="$t('Currency Setting')">
              <div v-if="currencyOptions.length > 0">
                <div v-for="(item, index) in currencyOptions" :key="index">
                  <div
                    v-if="
                      currencyOptions[index].name !== undefined &&
                        currencyOptions[index].name !== null
                    "
                    class="d-flex align-items-center"
                  >
                    <div
                      class="d-flex align-items-center"
                      style="width: 25%; border-radius: 4px; padding: 6px 18px; border: 1px solid #b4b7bd;"
                      :style="
                        item.is_active === false ? 'background: #f6f6f6' : ''
                      "
                    >
                      <img
                        :src="item.img"
                        :alt="item.iso_code"
                        style="width: 25px; height: 25px; object-fit: contain; display: block"
                        class="mr-2"
                      />{{ item.iso_code }} ({{ item.name }})
                    </div>
                    <!-- <b-form-group
                      label="Exchange"
                      label-cols-md="12"
                      class="ml-2 mb-0"
                    > -->
                    <validation-provider
                      #default="{ errors }"
                      name="exchange rate"
                      rules="required"
                    >
                      <b-input-group
                        prepend="Exchange rate |"
                        :append="`| ${currencyDefault.iso_code}`"
                        style="width: 300px;"
                        class="input-group-merge ml-2 mb-0"
                      >
                        <b-form-input
                          v-model="item.exchange" type="number" :readonly="item.origin === 1 ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                    <b-form-checkbox
                      v-model="item.is_active"
                      checked="true"
                      class="ml-2 mb-0 custom-control-success"
                      name="check-button"
                      switch
                    />
                    <!-- Remove Button -->
                    <!-- <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="ml-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button> -->
                  </div>
                  <div v-else>
                    <b-link v-b-modal.modal-xl>
                      {{ $t('Choose currency') }}
                    </b-link>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="text-right">
                <b-button
                  v-if="currencyOptions.length < limitcurrency"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>
                    {{ $t('Add new') }} ({{ currencyOptions.length }}/{{ limitcurrency }})
                  </span>
                </b-button>
              </div>
            </Fieldset>
          </b-col>
          <b-col md="3">
            <div class="position-sticky top">
              <Fieldset id="status" :title="$t('Status')">
                <hr />
                <!-- Submit -->
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="text-right"
                    @click.prevent="validationForm"
                  >
                    {{ $t('Update') }}
                  </b-button>
                </div>
              </Fieldset>
              <Fieldset id="currencydefault" :title="$t('Currency Default')">
                <div class="d-flex align-items-center w-100">
                  <img
                    :src="currencyDefault.img"
                    :alt="currencyDefault.locale"
                    style="width: 20px; height: 20px; object-fit: contain;"
                    class="mr-2"
                  />
                  <div class="w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Is active"
                      rules="required"
                    >
                      <v-select
                        v-if="currencyOptionsDefault"
                        v-model="currencyDefault"
                        label="display_name"
                        :options="currencyOptionsDefault"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #option="{ iso_code, name, img }">
                          <img
                            :src="img"
                            :alt="iso_code"
                            style="width: 15px; height: 15px; object-fit: contain;"
                            class="mr-2"
                          />
                          <span>{{ iso_code }} ({{ name }})</span>
                        </template>
                      </v-select>
                      <small class="text-danger mt-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <br />
                <small class="text-muted">
                  {{ $t('Select the currency to make your website available in') }}
                </small>
              </Fieldset>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unneeded-ternary */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  // BFormGroup,
  BButton,
  BForm,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import currencyJson from '@/libs/currency/currency'
import PopupCurrency from './PopupCurrency.vue'

export default {
  /* eslint-disable implicit-arrow-linebreak */
  /* eslint-disable operator-linebreak */
  /* eslint-disable function-paren-newline */
  components: {
    BButton,
    BRow,
    BCol,
    BFormInput,
    // BFormGroup,
    vSelect,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BLink,
    ValidationProvider,
    ValidationObserver,
    PopupCurrency,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      listcurrencyLength: 0,
      required,
      limitcurrency: 10,
      isShow: false,
      currencyDefault: {
        name: null,
        display_name: null,
        symbol: null,
        iso_code: null,
        img: null,
        is_active: true,
      },
      currencyJson: {
        currency: [],
      },
      currencyList: [],
      // currencyList: currencyJson.currency,
      currencyOptions: [],
      currencyOptionsDefault: [],
      currencyAddList: [],
    }
  },
  async mounted() {
    const currList = await this.loadCurrencyList()
    this.currencyJson.currency = currList
    // this.setCurrency()
    let currCurrency = null
    const curr = JSON.parse(localStorage.getItem('siteID')).currency !== null ? JSON.parse(localStorage.getItem('siteID')).currency : 'VND'
    currCurrency = this.currencyJson.currency.filter(
      x => x.iso_code === curr,
    )
    currCurrency[0].is_active = true
    this.currencyDefault = currCurrency[0]
    this.currencyOptions = await this.loadcurrency()
    this.currencyList = currList.map(val => {
      const item = { ...val }
      const isFound = this.currencyOptions.find(x => x.iso_code === val.iso_code)
      if (isFound !== undefined) {
        item.disable = true
      } else {
        item.disable = false
      }
      return item
    })
    this.limitcurrency = this.currencyList.length
  },
  methods: {
    async loadCurrencyList() {
      const list = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_CLIENT_API_URL}/platform_currency`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.items.map(val => {
              const item = {
                img: val.avatar,
                iso_code: val.iso_code,
                name: val.name,
                symbol: '',
              }
              list.push(item)
              return item
            })
            return list
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return list
    },
    handlerChoosecurrency(data) {
      this.currencyOptions.splice(this.currencyOptions.length - 1, 1)
      this.currencyOptions.push(data)
      this.currencyAddList.push(data)
    },
    repeateAgain() {
      this.currencyOptions.push({
        locale: null,
        name: null,
        img: null,
        is_active: true,
      })
    },
    removeItem(index) {
      this.model.subcurrency.splice(index, 1)
      if (this.model.subcurrency.length <= 0) {
        this.model.subcurrency.push({
          locale: null,
          name: null,
          img: null,
          is_active: true,
        })
      }
    },
    async setCurrency() {
      let currCurrency = null
      const listCurrency = await this.loadcurrency()
      if (
        localStorage.getItem('siteID') !== undefined &&
        localStorage.getItem('siteID') !== null
      ) {
        const curr = JSON.parse(localStorage.getItem('siteID')).currency !== null ? JSON.parse(localStorage.getItem('siteID')).currency : 'VND'
        currCurrency = this.currencyJson.currency.filter(
          x => x.iso_code === curr,
        )
        currCurrency[0].is_active = true
        this.currencyDefault = currCurrency[0]

        const findDefaultCurrency = listCurrency.find(
          x => x.iso_code === currCurrency[0].iso_code,
        )
        if (findDefaultCurrency === undefined) {
          const resLang = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/currency`,
            {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: currCurrency[0].name,
              code: currCurrency[0].iso_code,
              is_active: Number(currCurrency[0].is_active === true ? 1 : 0),
              img: 'null',
            },
          )
          if (resLang.status === 200) {
            if (resLang.data.status) {
              this.currencyOptions = []
              this.currencyOptions = await this.loadcurrency()
            }
          }
        } else {
          this.currencyOptions = []
          this.currencyOptions = listCurrency
        }
      }
    },
    async loadcurrency() {
      this.isShow = true
      const currencyOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/currencies?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.currencyOptionsDefault = []
            this.listcurrencyLength = res.data.data.items.length
            res.data.data.items.map(val => {
              const currencyFil = this.currencyJson.currency.filter(
                x => x.iso_code === val.code,
              )
              currencyFil[0].is_active = Boolean(val.is_active === 1 ? true : false)
              currencyFil[0].display_name = `${currencyFil[0].iso_code} (${currencyFil[0].name})`
              currencyFil[0].exchange = val.exchange
              currencyFil[0].origin = val.origin
              if (val.is_active !== 0) {
                this.currencyOptionsDefault.push(currencyFil[0])
              }
              currencyFil[0].id = val.id
              return currencyOptions.push(currencyFil[0])
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return currencyOptions
    },
    async updateSite(params) {
      await Request.put(
        this.$http,
        `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
        params,
      )
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.isShow = true
          try {
            if (this.currencyDefault.iso_code !== JSON.parse(localStorage.getItem('siteID')).currency) {
              const temp = JSON.parse(localStorage.getItem('siteID'))
              temp.currency = this.currencyDefault.iso_code
              localStorage.setItem('siteID', JSON.stringify(temp))
              this.updateSite({
                currency: this.currencyDefault.iso_code,
              })
              // window.location.reload()
              // this.isShow = true
              // return
            }
            let listUpdate = []
            listUpdate = this.currencyOptions.filter(
              this.comparer(this.currencyAddList, 'iso_code'),
            )
            if (listUpdate.length > 0) {
              if (listUpdate.find(val => val.is_active === false && val.iso_code === this.currencyDefault.iso_code) === undefined) {
                listUpdate.map(async val => {
                  const params = {
                    site_id: JSON.parse(localStorage.getItem('siteID')).id,
                    name: val.name,
                    code: val.iso_code,
                    exchange: Number(val.exchange),
                    is_active: Number(val.is_active === true ? 1 : 0),
                  }
                  const res = await Request.put(
                    this.$http,
                    `${process.env.VUE_APP_API_URL}/currency/${val.id}`,
                    params,
                  )
                  if (res.status === 200) {
                    this.isShow = false
                    if (res.data.status) {
                      this.setCurrency()
                      window.location.reload()
                      // this.$toast({
                      //   component: ToastificationContent,
                      //   position: 'top-right',
                      //   props: {
                      //     title: 'Congratulation !',
                      //     icon: 'CheckCircleIcon',
                      //     variant: 'success',
                      //     text: 'Update currency success',
                      //   },
                      // })
                      this.isShow = false
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Opps! Something wrong',
                          icon: 'AlertOctagonIcon',
                          variant: 'danger',
                          text: String(this.showError(res.data.error, ',')),
                        },
                      })
                      this.isShow = false
                    }
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: 'Cannot deactive default currency',
                  },
                })
                this.isShow = false
              }
            }
            if (this.currencyAddList.length > 0) {
              this.currencyAddList.map(async val => {
                const params = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  name: val.name,
                  code: val.iso_code,
                  exchange: Number(val.exchange),
                  is_active: Number(val.is_active === true ? 1 : 0),
                  img: 'null',
                }
                const res = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/currency`,
                  params,
                )
                if (res.status === 200) {
                  this.isShow = false
                  if (res.data.status) {
                    this.currencyAddList = []
                    this.setCurrency()
                    // this.$toast({
                    //   component: ToastificationContent,
                    //   position: 'top-right',
                    //   props: {
                    //     title: 'Congratulation !',
                    //     icon: 'CheckCircleIcon',
                    //     variant: 'success',
                    //     text: 'New currency was added',
                    //   },
                    // })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Opps! Something wrong',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: String(this.showError(res.data.error, ',')),
                      },
                    })
                  }
                }
              })
            }
          } catch (error) {
            this.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
