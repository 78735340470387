import { render, staticRenderFns } from "./PopupCurrency.vue?vue&type=template&id=147ddee2&scoped=true&"
import script from "./PopupCurrency.vue?vue&type=script&lang=js&"
export * from "./PopupCurrency.vue?vue&type=script&lang=js&"
import style0 from "./PopupCurrency.vue?vue&type=style&index=0&id=147ddee2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147ddee2",
  null
  
)

export default component.exports